.dot_background {
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background: url(../../images/dot-background.png) no-repeat center center fixed;
    background-size: 100%;
    pointer-events: none;
    z-index: -1;
}

.dot_background::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: url(../../images/flame-bird.png) no-repeat center center fixed;
    background-size: contain;
    pointer-events: none;
}