* {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #ffffff30 #ffffff00;
}

*:hover {
    scrollbar-color: #ffffff80 #ffffff00;
}

*::-webkit-scrollbar {
    width: .5rem;
}

*::-webkit-scrollbar-thumb {
    background-color: #ffffff30;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff80;
}

html, body, #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(297.56deg, #1AA3E0 0%, #0C6599 100%);
}

button {
    cursor: pointer;
    user-select: none;
}

button:active {
    opacity: 0.7;
}

button:disabled {
    cursor: not-allowed;
}

img, svg {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}